<template>
  <div class="login-page">
    <form @submit.prevent="login">
      <img src="~@/assets/img/logo.png" alt="logo" />
      <label class="login-field">
        <span>
          <eva-icon name="person-outline" fill="#FFF" width="20" height="20" />
        </span>
        <input type="text" required placeholder="請輸入帳號" v-model.trim="account" />
      </label>
      <label class="login-field">
        <span>
          <eva-icon name="lock-outline" fill="#FFF" width="20" height="20" />
        </span>
        <input type="password" required placeholder="請輸入密碼" v-model.trim="password" />
      </label>

      <label class="remember-account">
        <input type="checkbox" v-model="rememberAccount" />
        記住登入資訊
      </label>

      <button type="submit" class="login-btn">登入</button>
    </form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { apiLogin } from "@/apis/login";
import { apiGetPushMessagePermission } from "@/apis/push";

export default {
  data() {
    return {
      account: "",
      password: "",
      // 是否記住登入資訊
      rememberAccount: false
    };
  },

  methods: {
    // 登入
    login() {
      sessionStorage.removeItem("LOGOUT");
      this.updateLoading(true);
      apiLogin({
        Account: this.account,
        Password: this.password
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            if (response.data) {
              // 是否顯示客戶報價選單
              if (response.data[0].iS_QUOTEUSER) {
                localStorage.setItem(
                  "IS_QUOTEUSER",
                  response.data[0].iS_QUOTEUSER.toLowerCase().trim()
                );
              }

              // 根據角色類型進入不同頁面
              if (
                response.data[0].useR_TYPE.toLowerCase().trim() === "sale" ||
                response.data[0].useR_TYPE.toLowerCase().trim() === "admin"
              ) {
                // 業務 或 管理者
                sessionStorage.setItem("USER_INFO", JSON.stringify(response.data[0].useR_GUID));
                localStorage.setItem("USER_GUID", response.data[0].useR_GUID);
                // 取得推播管理權限
                this.getPushMessagePermission();
                this.$router.push({ path: "select-customer" });
              } else {
                // 客戶
                const customer = {
                  cusT_ID: response.data[0].cusT_ID, // 客戶ID
                  cusT_NOX: response.data[0].cusT_NOX, // 客戶編號
                  cusT_SNM: response.data[0].cusT_SNM, // 客戶名稱
                  stoR_CDX: response.data[0].stoR_CDX, // 門市代號
                  weeK_DAYS: response.data[0].weeK_DAYS, // 可送貨日
                  emlX_ADR: response.data[0].emlX_ADR, // Email
                  preX_DAY: response.data[0].preX_DAY, // 可預下幾天單
                  limiT_TIME: response.data[0].limiT_TIME, // 結單時間
                  restdaY_SEND: response.data[0].restdaY_SEND, // 是否可下假日單
                  arxX_DDX: response.data[0].arxX_DDX, // 結帳日
                  weB_URL: response.data[0].weB_URL // 教學影片
                };
                sessionStorage.setItem("USER_INFO", JSON.stringify(customer));
                localStorage.setItem("CUSTOMER_INFO", JSON.stringify(customer));
                localStorage.setItem("CART", JSON.stringify([]));
                // 是否被變更密碼
                response.data[0].iS_PASSWORD_RESET.toLowerCase().trim() === "true"
                  ? this.$router.push({ path: "information" })
                  : this.$router.push({ path: "frequently-bought" });
              }

              // 是否記住帳號資訊
              this.rememberAccount
                ? localStorage.setItem("REMEMBER_ACCOUNT", true)
                : localStorage.setItem("REMEMBER_ACCOUNT", false);
              // 儲存角色帳號
              localStorage.setItem("ACCOUNT", this.account);
            } else {
              this.$notify({
                title: "登入失敗",
                message: "帳號/密碼錯誤",
                type: "error",
                duration: 2500
              });
            }
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 取得推播管理權限
    getPushMessagePermission() {
      apiGetPushMessagePermission({
        userGuid: localStorage.USER_GUID
      }).then(response => {
        if (response.status === 200) {
          localStorage.setItem("PUSH_MESSAGE_PERMISSION", response.data);
        }
      });
    },

    ...mapActions(["updateLoading"])
  },

  created() {
    this.rememberAccount = localStorage.REMEMBER_ACCOUNT === "true" ? true : false;
    if (this.rememberAccount) {
      this.account = localStorage.ACCOUNT;
    }
  }
};
</script>

<style lang="scss">
.login-page {
  height: 100vh;
  background-image: url("~@/assets/img/login_top_bg.png");
  background-size: cover;

  form {
    position: absolute;
    border-radius: 10px;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.35);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url("~@/assets/img/login_bottom_bg.png");
    background-size: cover;
    padding: 60px;
    img {
      display: block;
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 60px;
    }

    .login-field {
      display: block;
      margin-bottom: 15px;
      display: flex;
      background-color: #fff;
      padding: 3px;
      border-radius: 25px;
      overflow: hidden;
      span {
        background-color: #e50013;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
      }
      input {
        flex: 1;
        height: 30px;
        line-height: 30px;
        border: none;
        padding: 0 15px;
      }
    }
    .remember-account {
      color: #fff;
    }
    .login-btn {
      display: block;
      width: 100%;
      text-align: center;
      height: 35px;
      line-height: 35px;
      background-color: #f5b800;
      color: #fff;
      border: none;
      border-radius: 25px;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken(#f5b800, 10%);
      }
    }
  }
}
</style>
